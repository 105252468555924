import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { FIND_BROWSING_CART_BY_CUSTOMER_ID_GQL } from '../../../../graphql';

export interface FindBrowsingCartByCustomerIdInput {
  customerId: number;
}

export interface FindBrowsingCartByCustomerIdEffectArgs {
  onComplete?: (cart: Cart | null) => void;
  onError?: (error: string) => void;
}

export interface FindBrowsingCartByCustomerIdEffectReturn {
  enact: (data: FindBrowsingCartByCustomerIdInput) => void;
  clear: () => void;
  data: Cart | null | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useFindBrowsingCartByCustomerIdEffect = (
  args?: FindBrowsingCartByCustomerIdEffectArgs
): FindBrowsingCartByCustomerIdEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | null | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doFindBrowsingCartByCustomerId, { loading }] = useLazyQuery(
    FIND_BROWSING_CART_BY_CUSTOMER_ID_GQL,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: { findBrowsingCartByCustomerId: Cart | null }) => {
        setData(data.findBrowsingCartByCustomerId);
        setSuccess(true);

        if (args?.onComplete) {
          args.onComplete(data.findBrowsingCartByCustomerId);
        }
      },
      onError: (errors) => {
        setSuccess(false);
        setErrorMessage(errors.message);

        if (args?.onError) {
          args.onError(errors.message);
        }
      },
    }
  );

  const findBrowsingCartByCustomerId = (
    data: FindBrowsingCartByCustomerIdInput
  ): void => {
    doFindBrowsingCartByCustomerId({ variables: { ...data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: findBrowsingCartByCustomerId,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
